








































































































































































































































































@import '~variables'

$padding = 4px

.timeline-container
  display flex
  width 100%
  border-radius $border-radius
  background-color black
  box-shadow $shadow-elevation-2
  color white
  user-select none
  .date-from, .date-to
    margin 0 24px
    padding $padding 0
    user-select none
  .data-visualizer
    position relative
    flex 2
    margin 2px 0
    border-radius $border-radius
    background-color white
    background-size contain !important
    cursor pointer
    &--loading
      cursor default
    &--livemode
      cursor default
    .focus-pin
      position absolute
      bottom 170%
      transform translateX(-50%)
    .cursor
      position relative
      z-index 1
      width 9px
      height 100%
      cursor grab
      &--transition
        transition left 0.3s ease
      &:active
        cursor grabbing
      &:after
        position relative
        left 50%
        z-index 2
        display block
        box-sizing border-box
        width 3px
        height 100%
        background-color black
        content ''
        transform translateX(-40%)
    .timeline__spinner, .timeline__text
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
    .timeline__text
      color black
      text-transform uppercase
      font-weight 700
      font-size $fs-h3
