.apps-tools {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  padding: 32px;
}
.color-helper {
  position: absolute;
  bottom: 75px;
  left: 32px;
  z-index: 2;
}
/*# sourceMappingURL=src/app/views/building-viewer/components/time-navigation.css.map */