















































































































@import '~variables'

.range-container
  padding 2px 0 2px 0
  width 20px
  background-size contain !important

.range-color
  margin auto
  width 20px
  height 20px
  border-width 1px 1px 0 1px
  border-style solid
  border-color black
  background-size 90px 30px !important
  &:last-child
    border-bottom 1px solid black

.slide-fade-enter-active, .slide-fade-leave-active
  transition all 0.3s ease

.slide-fade-enter, .slide-fade-leave-to
  opacity 0
  transform translateX(10px)

.color-label
  position relative
  display inline-flex
  justify-content center
  align-items center
  margin-left 28px
  padding 2px 8px
  width auto
  border-top-right-radius 10px
  border-bottom-right-radius 10px
  background-color $black
  color white
  white-space nowrap
  font-weight 500
  font-size $fs-body
  &:after
    position absolute
    left -17.5px
    width 0px
    height 0px
    border-top 9.5px solid transparent
    border-right 8px solid $black
    border-bottom 9.5px solid transparent
    border-left 9.5px solid transparent
    content ''
  &--max
    position absolute
    top -5px
  &--no-data
    position absolute
    bottom 0
