@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.timeline-container {
  display: flex;
  width: 100%;
  border-radius: 10px;
  background-color: #000;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #fff;
  user-select: none;
}
.timeline-container .date-from,
.timeline-container .date-to {
  margin: 0 24px;
  padding: 4px 0;
  user-select: none;
}
.timeline-container .data-visualizer {
  position: relative;
  flex: 2;
  margin: 2px 0;
  border-radius: 10px;
  background-color: #fff;
  background-size: contain !important;
  cursor: pointer;
}
.timeline-container .data-visualizer--loading {
  cursor: default;
}
.timeline-container .data-visualizer--livemode {
  cursor: default;
}
.timeline-container .data-visualizer .focus-pin {
  position: absolute;
  bottom: 170%;
  transform: translateX(-50%);
}
.timeline-container .data-visualizer .cursor {
  position: relative;
  z-index: 1;
  width: 9px;
  height: 100%;
  cursor: grab;
}
.timeline-container .data-visualizer .cursor--transition {
  transition: left 0.3s ease;
}
.timeline-container .data-visualizer .cursor:active {
  cursor: grabbing;
}
.timeline-container .data-visualizer .cursor:after {
  position: relative;
  left: 50%;
  z-index: 2;
  display: block;
  box-sizing: border-box;
  width: 3px;
  height: 100%;
  background-color: #000;
  content: '';
  transform: translateX(-40%);
}
.timeline-container .data-visualizer .timeline__spinner,
.timeline-container .data-visualizer .timeline__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.timeline-container .data-visualizer .timeline__text {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
}
/*# sourceMappingURL=src/app/views/building-viewer/components/timeline.css.map */