@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.cursor-info {
  position: absolute;
  bottom: 250%;
  left: 50%;
  background-color: #000;
  color: #fff;
  transform: translateX(-50%);
}
.cursor-info .cursor-info__mean {
  position: relative;
  text-align: center;
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.3rem;
}
.cursor-info .cursor-info__content {
  position: relative;
  white-space: nowrap;
  font-weight: 400;
}
.cursor-info .cursor-info__content:after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border-width: 20px 15px 0 15px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  content: '';
  transform: translateX(-50%);
}
/*# sourceMappingURL=src/app/views/building-viewer/components/cursor-info.css.map */