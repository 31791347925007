@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.range-container {
  padding: 2px 0 2px 0;
  width: 20px;
  background-size: contain !important;
}
.range-color {
  margin: auto;
  width: 20px;
  height: 20px;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #000;
  background-size: 90px 30px !important;
}
.range-color:last-child {
  border-bottom: 1px solid #000;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(10px);
}
.color-label {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 28px;
  padding: 2px 8px;
  width: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000;
  color: #fff;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1rem;
}
.color-label:after {
  position: absolute;
  left: -17.5px;
  width: 0px;
  height: 0px;
  border-top: 9.5px solid transparent;
  border-right: 8px solid #000;
  border-bottom: 9.5px solid transparent;
  border-left: 9.5px solid transparent;
  content: '';
}
.color-label--max {
  position: absolute;
  top: -5px;
}
.color-label--no-data {
  position: absolute;
  bottom: 0;
}
/*# sourceMappingURL=src/app/views/building-viewer/components/color-range-helper.css.map */