



























@import '~variables'

.focus-pin-container
  .focus-pin
    position relative
    padding 4px
    width 10px
    height @width
    border 8px solid white
    border-radius 50%
    background-color black
    box-shadow $shadow-elevation-1
    cursor default
    &:after
      position absolute
      bottom -13px
      left 50%
      width 0
      height 0
      border-width 10px 9.5px 0 9.5px
      border-style solid
      border-color white transparent transparent transparent
      content ''
      transform translateX(-50%)
